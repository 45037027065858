function identify_amplifica(nameUser,emailUser,companyUser) {
  var amplificConfig = document.getElementById('amplificConfig');

  if (amplificConfig) {
    return;
  }

  var amplificScript = document.createElement("script");
  amplificScript.id = 'amplificConfig';
  amplificScript.textContent = `
  window.Amplifiqueme = {};
  window.Amplifiqueme.onLoad = ()=> {
    window.Amplifiqueme.identify(
      {
        email: "${emailUser}",
        name: "${nameUser}",
        company: "${companyUser}"
      }, true)
  }`;
  amplificScript.type = 'text/javascript';

  document.body.appendChild(amplificScript);
}